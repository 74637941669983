<template>
    <div class="sidebar-logo-container" :class="{ collapse: collapse }" :style="{ backgroundColor: sideTheme === 'theme-dark' ? '#304156' : '#FFFFFF' }">
        <transition name="sidebarLogoFade">
            <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
                <img v-if="logo" :src="logo" class="sidebar-logo-collapse" />
                <!-- <h1 v-else class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }}</h1> -->
                <img v-if="collapse" class="sidebar-logo-img" :src="logoYlyk" alt="" />
            </router-link>
            <router-link v-else key="expand" class="sidebar-logo-link" to="/">
                <img v-if="logo" :src="logo" class="sidebar-logo" />
                <img v-if="!collapse" class="sidebar-logo-img" :src="logoYlyk" alt="" />
                <!-- <span class="sidebar-title" :style="{ color: sideTheme === 'theme-dark' ? variables.logoTitleColor : variables.logoLightTitleColor }">{{ title }}</span> -->
            </router-link>
        </transition>
    </div>
</template>

<script>
import logoImg from '@/assets/logo/logo_yun.png';
import logoYlkj from '@/assets/logo/logo_ylkj.png';
import variables from '../../../assets/styles/variables.scss';

export default {
    name: 'SidebarLogo',
    props: {
        collapse: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        variables() {
            return variables;
        },
        sideTheme() {
            // if (this.$store.state.settings.sideTheme === 'theme-dark') {
            //     console.log(this.variables);
            // } else {
            //     console.log(this.variables);
            // }
            return this.$store.state.settings.sideTheme;
        },
    },
    data() {
        return {
            title: '扬蓝云控',
            logo: logoImg,
            logoYlyk: logoYlkj,
        };
    },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
    opacity: 0;
}

.sidebar-logo-container {
    // position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #2b2f3a;
    overflow: hidden;

    & .sidebar-logo-link {
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;

        & .sidebar-logo {
            height: 30px;
            // vertical-align: middle;
            margin: 10px 0 auto 20px;
        }

        & .sidebar-logo-collapse {
            height: 20px;
            margin: 15px 0 auto 10px;
        }

        & .sidebar-title {
            display: inline-block;
            margin: 0;
            color: #fff;
            font-weight: 400;
            line-height: 50px;
            font-size: 20px;
            font-family: '微软雅黑';
            vertical-align: middle;
            background: linear-gradient(90deg, #0091fb 3%, #0e74e4 72%, #445cda 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &.collapse {
        .sidebar-logo {
            // margin: 20px 0 auto 0px;
            // // height: 20px;
        }
    }
}

.sidebar-logo-img {
    height: 30px;
    margin: 10px 0 auto 10px;
}
</style>
