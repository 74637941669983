import Vue from 'vue';

import Cookies from 'js-cookie';
import '@/assets/icon/hk/iconfont.css'

import '@/assets/styles/index.scss'; // global css
import '@/assets/styles/ruoyi.scss'; // ruoyi css

import App from './App';
import store from './store';
import router from './router';
import directive from './directive'; // directive
import plugins from './plugins'; // plugins
import './assets/icons'; // icon
import './permission'; // permission control

import busEvent from '@/utils/busEvent';
import { download } from '@/utils/request';
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from '@/utils/ruoyi';
import mqttTool from '@/utils/mqttTool'; // Mqtt工具

import { getDicts } from '@/api/system/dict/data';
import { getConfigKey } from '@/api/system/config';

import Pagination from '@/components/Pagination'; // 分页组件
import RightToolbar from '@/components/RightToolbar'; // 自定义表格工具组件=
import Editor from '@/components/Editor'; // 富文本组件
import FileUpload from '@/components/FileUpload'; // 文件上传组件
import ImageUpload from '@/components/ImageUpload'; // 图片上传组件
import ImagePreview from '@/components/ImagePreview'; // 图片预览组件
import DictTag from '@/components/DictTag'; // 字典标签组件
import DictData from '@/components/DictData'; // 字典数据组件

import Element from 'element-ui';
import './assets/styles/element-variables.scss';
import VueMeta from 'vue-meta'; // 头部标签组件
import * as echarts from 'echarts'; // Echart
import VueClipboard from 'vue-clipboard2'; // 一键复制粘贴板组件
import dataV from '@jiaminghi/data-view'; // DataV

import ItemWrap from './views/bigScreen/components/item-wrap/item-wrap.vue';
import Message from './views/bigScreen/components/message/message.vue';
import Reacquire from './views/bigScreen/components/reacquire/reacquire.vue';
import 'element-ui/lib/theme-chalk/index.css';

if (process.env.VUE_APP_MARS3D_SOURCE !== 'module') {
    window.Cesium = require('mars3d-cesium')
}

// 大屏组件
Vue.component('ItemWrap', ItemWrap);
Vue.component('Message', Message);
Vue.component('Reacquire', Reacquire);



// 全局方法挂载
Vue.prototype.$busEvent = busEvent;
Vue.prototype.download = download;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.handleTree = handleTree;
Vue.prototype.$mqttTool = mqttTool;
Vue.prototype.getDicts = getDicts;
Vue.prototype.getConfigKey = getConfigKey;
Vue.prototype.$echarts = echarts;

// 全局组件挂载
Vue.component('Pagination', Pagination);
Vue.component('RightToolbar', RightToolbar);
Vue.component('Editor', Editor);
Vue.component('FileUpload', FileUpload);
Vue.component('ImageUpload', ImageUpload);
Vue.component('ImagePreview', ImagePreview);
Vue.component('DictTag', DictTag);

Vue.use(plugins);
Vue.use(directive);
Vue.use(VueMeta);
Vue.use(VueClipboard);
Vue.use(dataV);

DictData.install();

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
    size: Cookies.get('size') || 'medium', // set element-ui default size
});

Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, vm, info) {
    console.error('Vue Error Handler:', err, info);
    // Custom error handling logic if needed
};


new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App),
});

// 表格带边框
Element.Table.props.border = {
    default: true,
    type: Boolean,
};
