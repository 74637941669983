<template>
    <div id="app" style="background-color: #0e2e87" v-if="$route.meta.bigScreen">
        <router-view />
    </div>
    <div id="app" v-else>
        <router-view />
        <theme-picker />
    </div>
</template>

<script>
import ThemePicker from '@/components/ThemePicker';
import { listAllDeviceShort } from '@/api/iot/device';
// admin
// ylykroot99
// https://kdocs.cn/l/cmaP7fWJ4fAN
export default {
    name: 'App',
    components: { ThemePicker },
    metaInfo() {
        return {
            title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
            titleTemplate: (title) => {
                return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE;
            },
        };
    },
    methods: {
        //处理设备报警通知
        handleWarnNotify(data) {
            const device = data.device;
            const detail = data.detail;
            this.$notify({
                type: 'warning',
                title: `${data.alertName}`,
                dangerouslyUseHTMLString: true,
                message: `
                  <div style="color: #000;">
                    <div>设备名称：${data.deviceName}</div>
                    <div>设备编号：${data.serialNumber}</div>
                    <div>所属部门：${device.deptName}</div>
                    <div>&emsp;负责人：${device.workerName || '无'}</div>
                    <div>所在地址：${device.networkAddress}</div>
                    <div>报警时间：${detail?.ts}</div>
                  </div>
                  `,
                duration: 0,
                onClick() {},
            });
        },
        //初始化Mqtt
        initMqtt() {
            if (!this.$store.state.user?.token) {
                return;
            }

            //查询所有的设备进行订阅
            listAllDeviceShort().then(({ rows: deviceList }) => {
                if (!this.$mqttTool.client) {
                    this.$mqttTool.connect();
                }
                const h = this.$createElement;
                const topics = [];
                for (let device of deviceList) {
                    topics.push(`/+/${device.serialNumber}/alert/post`);
                }
                this.$mqttTool.subscribe(topics);
                this.$mqttTool.client.on('message', (topic, message, buffer) => {
                    if (!topic.includes('/alert/post')) {
                        return;
                    }
                    let msg = String(message);
                    const data = JSON.parse(msg);
                    this.handleWarnNotify(data);
                });
            });
        },
    },
    created() {
        this.initMqtt();
        this.$busEvent.$on('user-login', this.initMqtt);
    },
    beforeDestroy() {
        this.$busEvent.$off('user-login', this.initMqtt);
        this.$mqttTool.end();
    },
};
</script>
<style scoped lang="scss">
// #app .theme-picker {
//     display: none;
// }

.d-flex {
    display: flex;
    align-items: center;
}

.d-flex-jsb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

::v-deep .el-input__inner {
    border: 1px solid #ccc !important;
}

::v-deep .el-input__inner:focus {
    border: 1px solid rgb(59 130 246) !important;
}
</style>
