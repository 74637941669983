import axios from 'axios' // 使用前要先安装依赖：npm install axios

const service = axios.create({
    baseURL: '/baiduApi',
    timeout: 60 * 1000, // 请求超时时间
})


axios.defaults.headers['Content-Type'] = 'application/multipart/form-data;charset=utf-8'

// 请求拦截器
const requestInterceptor = (config) => {
    // 这里可以进行请求加密等操作。如添加token,cookie，修改数据传输格式等。
    // config.headers['token'] = 'xxxx';
    // config.headers['Content-type'] = 'application/json';
    config.headers['Cache-Control'] = 'no-cache'
    return config;
}

service.interceptors.request.use(requestInterceptor)

// 响应拦截器
const responseInterceptor = (response) => {
    // 请求成功进行的操作。可以使用switch/if...else对数据先进行处理。
    return response.data;
}

const errorInterceptor = (error) => {
    // 请求失败进行的操作
    return Promise.reject(error);
}

service.interceptors.response.use(responseInterceptor, errorInterceptor)


export default service 
