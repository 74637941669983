import request from '@/utils/request';
import service from '@/utils/requestBaidu';

// 获取文件URl
export function getFileUrl(fileName) {
    return request({
        url: '/oss/detail/list?originalName=' + fileName,
        method: 'get',
    });
}


// 百度地图查询api
export function getBaiduSearch(query) {
    return service({
        url: '/place/v2/search',
        method: 'GET',
        params: query,
    });
}

//百度地图详细查询api
export function getBaiduDetail(query) {
    return service({
        url: '/place/v2/detail',
        method: 'GET',
        params: query,
    });
}


// 设备分配部门和负责人
export function listDevice(query) {
    return request({
        url: '/iot/device/list',
        method: 'get',
        params: query,
    });
}

// 获取设备状态
export function getDeviceStatus(query) {
    return request({
        url: '/system/dict/data/type/iot_device_status',
        method: 'get',
        params: query,
    });
}

// 获取部门信息
export function getDepartment(query) {
    return request({
        url: '/system/dept/list',
        method: 'get',
        params: query,
    });
}

//获取负责人信息
export function getUserManage(query) {
    return request({
        url: '/system/user/list',
        method: 'get',
        params: query,
    });
}

// 查询设备分组列表
export function listGroup(query) {
    return request({
        url: '/iot/group/list',
        method: 'get',
        params: query,
    });
}

// 查询未授权设备列表
export function listUnAuthDevice(query) {
    return request({
        url: '/iot/device/unAuthlist',
        method: 'get',
        params: query,
    });
}

// 查询分组可添加设备分页列表
export function listDeviceByGroup(query) {
    return request({
        url: '/iot/device/listByGroup',
        method: 'get',
        params: query,
    });
}

// 查询设备简短列表
export function listDeviceShort(query) {
    return request({
        url: '/iot/device/shortList',
        method: 'get',
        params: query,
    });
}

// 查询所有设备简短列表
export function listAllDeviceShort(data) {
    return request({
        url: '/iot/device/all',
        method: 'get',
        params: data,
    });
}

// 查询设备详细
export function getDevice(deviceId) {
    return request({
        url: '/iot/device/' + deviceId,
        method: 'get',
    });
}

// 设备数据同步
export function deviceSynchronization(serialNumber) {
    return request({
        url: '/iot/device/synchronization/' + serialNumber,
        method: 'get',
    });
}

// 根据设备编号查询设备详细
export function getDeviceBySerialNumber(serialNumber) {
    return request({
        url: '/iot/device/getDeviceBySerialNumber/' + serialNumber,
        method: 'get',
    });
}

// 查询设备统计信息
export function getDeviceStatistic() {
    return request({
        url: '/iot/device/statistic',
        method: 'get',
    });
}

// 选择分配设备
export function distributionDevice(deptId, deviceIds, workerId) {
    var URL = '';
    if (workerId === null) {
        URL = '/iot/device/assignment?deptId=' + deptId + '&deviceIds=' + deviceIds;
    } else {
        URL = '/iot/device/assignment?deptId=' + deptId + '&deviceIds=' + deviceIds + '&workerId=' + workerId;
    }
    return request({
        url: URL,
        method: 'post',
    });
}
//回收设备
export function recycleDevice(deviceIds) {
    return request({
        url: '/iot/device/recovery?deviceIds=' + deviceIds,
        method: 'post',
    });
}
//查询设备导入记录
export function listImportRecord() {
    return request({
        url: '',
        method: 'get',
    });
}
//查询设备回收记录
export function listRecycleRecord() {
    return request({
        url: '',
        method: 'get',
    });
}
//查询设备分配记录
export function listAllotRecord() {
    return request({
        url: '',
        method: 'get',
    });
}

// 查询设备运行状态详细
export function getDeviceRunningStatus(params) {
    return request({
        url: '/iot/device/runningStatus',
        method: 'get',
        params: params,
    });
}

// 查询设备物模型的值
export function getDeviceThingsModelValue(deviceId) {
    return request({
        url: '/iot/device/thingsModelValue/' + deviceId,
        method: 'get',
    });
}

// 新增设备
export function addDevice(data) {
    return request({
        url: '/iot/device',
        method: 'post',
        data: data,
    });
}

// 修改设备
export function updateDevice(data) {
    return request({
        url: '/iot/device',
        method: 'put',
        data: data,
    });
}

// 设备权限（部门）、负责人批量设置
export function setDeptOrWorker(data) {
    return request({
        url: '/iot/device/setDeptOrWorker',
        method: 'put',
        data: data,
    });
}

// 删除设备
export function delDevice(deviceId) {
    return request({
        url: '/iot/device/' + deviceId,
        method: 'delete',
    });
}

// 生成设备编号
export function generatorDeviceNum(params) {
    return request({
        url: '/iot/device/generator',
        method: 'get',
        params: params,
    });
}

export function getGwDevCode(params) {
    return request({
        url: '/iot/device/gwDevCount',
        method: 'get',
        params: params,
    });
}

//mqtt连接参数查看
export function getMqttConnect(params) {
    return request({
        url: '/iot/device/getMqttConnectData',
        method: 'get',
        params: params,
    });
}
