import { render, staticRenderFns } from "./item-wrap.vue?vue&type=template&id=461640e6&scoped=true"
import script from "./item-wrap.vue?vue&type=script&lang=js"
export * from "./item-wrap.vue?vue&type=script&lang=js"
import style0 from "./item-wrap.vue?vue&type=style&index=0&id=461640e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461640e6",
  null
  
)

export default component.exports